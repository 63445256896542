import React from 'react'
import SEO from './seo'
import Navigation from './navigation'
import useSiteMetadata from '../hooks/metadata'

import '../styles/global.scss'

interface LayoutProps {
  children?: React.ReactNode
  location?: { pathname: string }
}

export default function Layout(props: LayoutProps): JSX.Element {
  const { copyright } = useSiteMetadata()

  return (
    <>
      <SEO location={props.location} />
      <header>
        <Navigation />
      </header>
      <main id="full-body-wrapper">{props.children}</main>
      <footer className="footer">{copyright}</footer>
    </>
  )
}
