import React from 'react'
import Helmet from 'react-helmet'
import useSiteMetadata from '../hooks/metadata'

interface SEOPropTypes {
  title?: string
  desc?: string
  location?: { pathname: string }
}

export default function SEO(props: SEOPropTypes): JSX.Element {
  const site = useSiteMetadata()

  const seo = {
    title: props.title ?? site.title,
    desc: props.desc ?? site.description,
    url: site.siteUrl + (props.location ? props.location.pathname : '')
  }

  return (
    <Helmet
      defaultTitle={seo.title}
      titleTemplate={`%s | ${seo.title}`}
      link={props.location ? [{ rel: 'canonical', href: seo.url }] : []}
    >
      <meta name="description" content={seo.desc} />
    </Helmet>
  )
}
